/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// You can delete this file if you're not using it

import './src/styles/css/bootstrap.min.css';
import "./src/styles/css/animate.min.css"
import "./src/styles/css/boxicons.min.css"
import "./src/styles/css/flaticon.css"
import 'react-accessible-accordion/dist/fancy-example.css'
import 'react-18-image-lightbox/style.css';
import "/node_modules/aos/dist/aos.css";
import "swiper/css"
import "swiper/css/bundle"

// Global style
import "./src/styles/css/style.scss" 
import "./src/styles/css/responsive.css" 

// If you want RTL style comment out the below rtl.css
// import "./src/styles/css/rtl.css" 
 

// Possible fix for Uncaught Error: Minified React error #418 - Hydrating a server-rendered container that was deleted or replaced
import ReactDOM from "react-dom/client";


export const replaceHydrateFunction = () => {
  return (element, container) => {
    const root = ReactDOM.createRoot(container)
    root.render(element)
  }
}
